import React, { useEffect } from "react";
import {AcControllerSetting, AssetCmd} from "../types";

import acControllerArrowIcon from "../../acController/assets/acControllerArrowIcon.svg";
import horiZontalLine from "../../../assets/image/horizontalLine.svg";
import acControllerSuccessIcon from "../../acController/assets/acControllerSuccessIcon.svg";
import {getAssetAckCommand, updateAcControllerSettings} from "../service/AcControllerApi";
import { FieldValidatorUtil } from "../../../Utils/fieldValidation/FieldValidatorUtil";
import { useQuery, useQueryClient } from "react-query";
import LocalStorageService from "../../../Utils/LocalStorageService";
import {cancelExistingQuery} from "../../../Utils/utils";
import useState from "react-usestateref";
import moment from "moment/moment";

type PropTypes = {
  acControllerSetting: AcControllerSetting;
  assetId: string;
  macId: string;
  assetCmdData: AssetCmd;
};

function TemperatureMode({ acControllerSetting, assetId, macId, assetCmdData, }: PropTypes) {
  const queryClient = useQueryClient();
  const [temperatureModeEnabled, setTemperatureModeEnabled] =
      useState<boolean>(false);

  const [selectedTemp, setSelectedTemp] = useState("");
  const [applyButtonDisabled, setApplyButtonDisabled] = useState(true);

  const [isUpdatePermission, setIsUpdatePermission] = useState(false);

  const [isApplyButtonEnabled, setIsApplyButtonEnabled] = useState(false);

  const [apiCallState, setApiCallState, apiCallStateRef] = useState({
    buttonText: "Apply",
    timeRemaining: 15,
    state: "",
  });

  const [currentPollingIndex, setCurrentPollingIndex, currentPollingIndexRef] =
      useState(0);
  const [timeoutVal, setTimeoutVal, timeoutValRef] = useState<any>(null);
  const [timeRemainingVal, setTimeRemainingVal, timeRemainingValRef] =
      useState<any>(null);
  const [currentTimeStamp, setCurrentTimeStamp, currentTimeStampRef] =
      useState(0);

  const acknowledgementTimeData = Number(assetCmdData?.ackTime);
  const defaultPollInterval = 0;
  const acknowledgementTime = assetCmdData?.ack === "Y" ?
      (assetCmdData.hasOwnProperty("ackTime") && !Number.isNaN(acknowledgementTimeData)) ?
          acknowledgementTimeData : defaultPollInterval : defaultPollInterval;
  const pollInterval = 5 * 1000; // 5 seconds

  const { data, error, isLoading, isFetching, refetch, dataUpdatedAt } =
      useQuery(
          "updateAcControllerSettingsForTemp",
          () =>
              updateAcControllerSettings({
                commandsJson: {
                  cmd: assetCmdData.cmdId,
                  deviceId: `'${macId}'`,
                  tempMode: {
                    tempFlag: true,
                    setTemp: Number(selectedTemp),
                  },
                },
                fixedAssetId: assetId,
                topic: assetCmdData.topic,
              }),
          { enabled: false, keepPreviousData: false }
      );

  const {
    data: ackData,
    error: ackError,
    isLoading: ackIsLoading,
    isFetching: ackIsFetching,
    refetch: ackRefetch,
    dataUpdatedAt: ackDataUpdatedAt,
  } = useQuery(
      "getAssetAckCommandTemperatureMode",
      () => getAssetAckCommand(macId, assetId, currentTimeStampRef.current),
      {
        enabled: false,
        keepPreviousData: false,
      }
  );

  /**
   * Call the Triggred Api
   */
  const updateSettingInput = async () => {
    cancelExistingQuery("updateAcControllerSettingsForTemp", queryClient);
    const temp = {
      ...apiCallStateRef.current,
      state: "triggredApiLoading",
      timeRemaining: acknowledgementTime,
    };
    setApiCallState(temp);

    const currentTimestamp = moment()
        .valueOf();
    setCurrentTimeStamp(currentTimestamp);

    try {
      await refetch();
    } catch (error) {
      const temp = {
        ...apiCallStateRef.current,
        state: "",
        buttonText: "Retry",
      };
      setApiCallState(temp);
      console.error("Error re-fetching data:", error);
    }
  };

  /**
   * Fetch the Acknowledment Api
   */
  const fetchAckCommand = async () => {
    if (apiCallStateRef.current.timeRemaining < 5) {
      const temp = {
        ...apiCallStateRef.current,
        state: "",
        buttonText: "Retry",
      };
      setApiCallState(temp);
      clearPolling();
    } else {
      setCurrentPollingIndex((prev) => prev + 1);
      try {
        await ackRefetch();
      } catch (error) {
        const temp = {
          ...apiCallStateRef.current,
          state: "",
          buttonText: "Retry",
        };
        setApiCallState(temp);
        console.error("Error re-fetching for Ack command:", error);
      }
    }
  };

  const clearPolling = () => {
    setCurrentPollingIndex(0);
    setCurrentTimeStamp(0);
    clearInterval(timeoutValRef.current);
    clearInterval(timeRemainingValRef.current);
    cancelExistingQuery("getAssetAckCommandUiMode", queryClient);
    const temp = {
      ...apiCallStateRef.current,
      timeRemaining: acknowledgementTime,
    };
    setApiCallState(temp);
  };

  const handleArrowClicks = () => {
    setTemperatureModeEnabled((prev) => !prev);
  };

  const handleTempValue = (value: string) => {
    if (value.includes(".")) {
      return;
    }
    if (value === "") {
      setSelectedTemp(value);
      setApplyButtonDisabled(false);
      return;
    }
    const regex = /^[+-]?\d+$/;
    const validate = new FieldValidatorUtil();

    if (validate.isAssetAttributeValidate(value, regex)) {
      setSelectedTemp(value);
      const floatValue = parseInt(value);
      if (!isNaN(floatValue)) {
        // Check if the value is within the valid range
        if (floatValue >= 16 && floatValue <= 30) {
          setSelectedTemp(value);
          setApplyButtonDisabled(false);
        } else {
          setApplyButtonDisabled(true);
        }
      }
    } else {
      setApplyButtonDisabled(true);
    }
  };

  const updateUseState = () => {
    const tempValue = acControllerSetting.data.setTemp;
    handleTempValue(tempValue?.toString());
  };

  const checkSecurityPermission = () => {
    const permissions = LocalStorageService.getSecurityPermissionData();
    const status = permissions?.includes("AST_CTRL_UPDATE");
    setIsUpdatePermission(status);
  }

  const updateRemainingTimeForAck = () => {
    const interval = setInterval(() => {
      const temp = {
        ...apiCallStateRef.current,
        timeRemaining: apiCallStateRef.current.timeRemaining - 1,
      };
      setApiCallState(temp);
    }, 1 * 1000);
    setTimeRemainingVal(interval);
  };

  const poolAckCommand = () => {
    const temp = {
      ...apiCallStateRef.current,
      state: "ackCmdApiLoading",
      timeRemaining: acknowledgementTime,
    };
    setApiCallState(temp);
    updateRemainingTimeForAck();
    fetchAckCommand();
    const interval = setInterval(() => {
      fetchAckCommand();
    }, pollInterval);
    setTimeoutVal(interval);
  };

  useEffect(() => {
    if (
        acControllerSetting !== undefined &&
        acControllerSetting.hasOwnProperty("key") &&
        acControllerSetting.key !== ""
    ) {
      updateUseState();
    }
  }, [acControllerSetting]);

  useEffect(() => {
    if (data !== undefined && data === 200) {
      if(acknowledgementTime === 0){
        const temp = {
          ...apiCallStateRef.current,
          state: "success",
        };
        setApiCallState(temp);
        setTimeout(() => {
          const temp = {
            ...apiCallStateRef.current,
            state: "",
          };
          setApiCallState(temp);
        }, 3000);
      }else {
        poolAckCommand();
      }
    }
  }, [data, dataUpdatedAt]);

  useEffect(() => {
    if (ackData?.triggerAck) {
      clearPolling();
      let triggerAck = ackData?.triggerAck;
      triggerAck = JSON.parse(triggerAck);
      const temp = {
        ...apiCallStateRef.current,
        state: "success",
      };
      setApiCallState(temp);
      setTimeout(() => {
        const temp = {
          ...apiCallStateRef.current,
          state: "",
        };
        setApiCallState(temp);
      }, 3000);
    }
  }, [ackData, dataUpdatedAt]);

  useEffect(() => {
    if(apiCallStateRef.current.timeRemaining === 0) {
      const temp = {
        ...apiCallStateRef.current,
        state: "",
        buttonText: "Retry",
      };
      setApiCallState(temp);
      clearPolling();
    }
  }, [apiCallStateRef.current]);

  useEffect(() => {
    clearInterval(timeoutValRef.current);
    clearInterval(timeRemainingValRef.current);
    cancelExistingQuery("updateAcControllerSettingsForTemp", queryClient);
    cancelExistingQuery("getAssetAckCommandTemperatureMode", queryClient);
    checkSecurityPermission();
    return () => {
      clearInterval(timeoutValRef.current);
      clearInterval(timeRemainingValRef.current);
      cancelExistingQuery("updateAcControllerSettingsForTemp", queryClient);
    };
  }, []);

  return (
      <>
        {/* For Temperature Mode */}
        <div className="bodyContentMainDiv">
          <div
              className="acControllerHeadingDiv"
              onClick={() => handleArrowClicks()}
          >
            <img
                src={acControllerArrowIcon}
                className={`acControllerArrowIcon ${
                    temperatureModeEnabled ? "rotate-90" : "rotate-0"
                }`}
            />
            <p className="acControllerHeadingText">Temperature Mode</p>
          </div>
          {temperatureModeEnabled && (
              <>
                <div className="acControllerDataInputDiv">
                  <div className="acControllerDataDiv">
                    <p className="acControllerCategory">Actual set point</p>
                    <div className="tempInputDiv">
                      <input
                          className="temperatureInput"
                          type="text"
                          value={selectedTemp}
                          onChange={(e) => {
                            setIsApplyButtonEnabled(true);
                            handleTempValue(e.target.value)
                          }
                          }
                          readOnly={!isUpdatePermission}
                      />
                      <img className="horizontalImage" src={horiZontalLine} />
                      <p className="tempUnit">°C</p>
                    </div>
                    {applyButtonDisabled && selectedTemp !== "" && (
                        <p className="tempErrorMessage">
                          Enter a value between 16°C to 30°C
                        </p>
                    )}
                  </div>
                </div>
                { isUpdatePermission && (
                    <>
                      {
                        !isApplyButtonEnabled ? (
                            <button
                                className="disabledApplyButtonDiv"
                                disabled={true}
                            >
                              <p className="disabledApplyButtonText">Apply</p>
                            </button>
                        ): (
                            <>
                              {apiCallStateRef.current.state === "" ? (
                                  <button
                                      className="applyButtonDiv"
                                      onClick={() => {
                                        if (selectedTemp !== "" && !applyButtonDisabled) {
                                          updateSettingInput();
                                        }
                                      }}
                                      disabled={!isApplyButtonEnabled}
                                      style={{
                                        opacity: selectedTemp === "" || applyButtonDisabled ? 0.3 : 1,
                                        cursor:
                                            selectedTemp === "" || applyButtonDisabled
                                                ? "default"
                                                : "pointer",
                                      }}
                                  >
                                    <p className="applyButtonText">
                                      {apiCallStateRef.current.buttonText}
                                    </p>
                                  </button>
                              ) : apiCallStateRef.current.state === "triggredApiLoading" ||
                              apiCallStateRef.current.state === "ackCmdApiLoading" ? (
                                  <div className="loading-container">
                                    <div className="loading-spinner"></div>
                                    {apiCallStateRef.current.state === "ackCmdApiLoading" &&
                                        apiCallStateRef.current.timeRemaining >= 0 && (
                                            <p className="acknowledgementWaitText">
                                              Awaiting acknowledgement{" "}
                                              {apiCallStateRef.current.timeRemaining}s
                                            </p>
                                        )}
                                  </div>
                              ) : apiCallStateRef.current.state === "success" ? (
                                  <div className="successDivCont">
                                    <img src={acControllerSuccessIcon} alt="success-icon" />
                                    <p className="successText"> Success</p>
                                  </div>
                              ) : (
                                  <></>
                              )}
                            </>
                        )
                      }
                    </>
                )
                }
              </>
          )}
        </div>
      </>
  );
}

export default TemperatureMode;
